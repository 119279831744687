<template>
  <SharedContainerForm :no-logo="true" class="authorization-form">
    <template #form>
      <div class="authorization-form__inner">
        <!-- <SharedButton v-bind="buttonOptions" :disabled="pending" @click="emit('onRegistration')">
          Быстрая регистрация
        </SharedButton>

        <div v-if="error" class="authorization-form__errors">
          <SharedText as="span" :color="Colors.ADDITIONAL.RED">{{ error }}</SharedText>
        </div>

        <SharedButton v-bind="buttonOptions" :disabled="pending" @click="emit('onLogin')">
          Авторизация по логину и паролю
        </SharedButton> -->

        <div class="authorization-form__footer">
          <SharedGroupIcons
            align-items="stretch"
            :icons="getIcons"
            :is-disabled="pending"
            is-show-text
            width-text="100%"
            flex-direction="column"
            @on-icon="handlerClickIcon"
          >
            <div class="authorization-form__footer--description">{{ $t('authBySocials') }}</div>
          </SharedGroupIcons>
        </div>
      </div>
    </template>
  </SharedContainerForm>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import SharedContainerForm from '~/components/SharedContainerForm/SharedContainerForm.vue';
import type {
  IAuthorizationFormEmit,
  IAuthorizationFormProps,
} from '~/features/authentication/components/AuthorizationForm/AuthorizationForm.types';
import { defaultIcons } from '~/features/authentication/components/AuthorizationForm/AuthorizationForm.data';
import type { ISharedIconSocial } from '~/components/SharedGroupIcons/SharedGroupIcons.types';
import type { Type } from '~/repository/modules/auth';

const emit = defineEmits<IAuthorizationFormEmit>();
const props = defineProps<IAuthorizationFormProps>();

const getIcons = computed<ISharedIconSocial[]>(() => {
  return props.icons || defaultIcons;
});

const handlerClickIcon = (type: Type.TTypesOAuthKey) => {
  emit('onSocial', type);
};
</script>

<style src="./AuthorizationForm.scss" scoped lang="scss"></style>
