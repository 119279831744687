<template>
  <component :is="logoIcon" :key="logoIcon" class="shared-logo__icon" />
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import type { IPropsSharedLogo } from '~/components/SharedLogo/SharedLogo.type';
import { Breakpoints } from '~/constants/media.constants';

import { EMainPageEvents } from '~/features/mainPage/constants/events.constants';

const LOGO_DEFAULT_NAME = 'SvgoLogoColor';

const props = withDefaults(defineProps<IPropsSharedLogo>(), {
  name: 'td',
});
const { name } = toRefs(props);
const viewport = useViewport();

const isMobileSize = () => viewport.breakpoint.value === Breakpoints.sm;

const logoIcon = computed<string>(() => {
  if (props.eventId && props.eventId === EMainPageEvents.TASTY_7) {
    return isMobileSize() ? 'SvgoLogoMobileTD7' : 'SvgoLogoTD7';
  }

  const logoNameWithoutSpecifyingProject = isMobileSize() ? LOGO_DEFAULT_NAME + 'Mobile' : LOGO_DEFAULT_NAME;
  return logoNameWithoutSpecifyingProject + name.value.toUpperCase() + 'Icon';
});
</script>

<style src="./SharedLogo.scss" lang="scss" scoped></style>
