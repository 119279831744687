import type { IItemSharedGroupLinks } from '~/components/SharedGroupLinks/SharedGroupLinks.types';
import { FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';

export const keysTitle = {
  login: 'Логин:',
  password: 'Пароль:',
};

export const defaultOptionsPlaceholder = { color: Colors.NEUTRAL.WHITE };

export const linksRegistrationForm: IItemSharedGroupLinks[] = [
  {
    textProps: {
      weight: FontWeights.BOLD,
    },
    title: 'Сохранить в файл',
  },
  {
    textProps: {
      weight: FontWeights.BOLD,
    },
    title: 'Сохранить картинкой',
  },
];
