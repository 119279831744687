import type { ITextProps } from '~/types/SharedFont.types';
import { FontFamily, FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';

export const defaultTextOptions: ITextProps = {
  color: Colors.NEUTRAL.WHITE,
  fontCase: 'upper',
  fontFamily: FontFamily.REAVER,
  weight: FontWeights.SEMIBOLD,
};
