import type { ILoginFormEmit } from './LoginForm.types';
import { Colors } from '~/constants/colors.constants';
import type { IItemSharedGroupLinks } from '~/components/SharedGroupLinks/SharedGroupLinks.types';
import { FontWeights } from '~/types/SharedFont.types';

export const defaultOptionsPlaceholder = { color: Colors.NEUTRAL.WHITE };

export const linksLoginForm = (emit: ILoginFormEmit): IItemSharedGroupLinks[] => [
  {
    cb() {
      emit('on-back');
    },
    textProps: {
      weight: FontWeights.BOLD,
    },
    title: '←  Вход через соц сети',
  },
];
