<template>
  <div class="shared-display-data">
    <div v-for="(val, key) in info" :key="val" class="shared-display-data__item">
      <SharedText class="shared-display-data__button" v-bind="bindDefaultTextPropsTitle">{{
        keysTitle[key]
      }}</SharedText>
      <SharedText
        class="shared-display-data__button shared-display-data__button--value"
        v-bind="bindDefaultTextPropsValue"
        >{{ val }}
      </SharedText>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { IPropsSharedDisplayData } from '~/components/SharedDisplayData/SharedDisplayData.types';
import type { ITextProps } from '~/types/SharedFont.types';
import { defaultTextPropsTitle, defaultTextPropsValue } from '~/components/SharedDisplayData/SharedDisplayData.data';

const props = defineProps<IPropsSharedDisplayData>();

const bindDefaultTextPropsTitle = computed<ITextProps>(() => {
  return props.textPropsTitle || defaultTextPropsTitle;
});

const bindDefaultTextPropsValue = computed<ITextProps>(() => {
  return props.textPropsValue || defaultTextPropsValue;
});
</script>

<style src="./SharedDisplayData.scss" scoped lang="scss"></style>
