<template>
  <div>
    <SharedText
      v-for="(link, i) in links"
      :key="link.title"
      :to="link.to || ''"
      v-bind="link.textProps"
      class="shared-link"
      :style="styles"
      as="span"
      @click="link.cb && link.cb()"
    >
      <span v-if="i % 2 !== 0" class="shared-link__dotted">•</span>
      {{ link.title }}
    </SharedText>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed, toRefs } from 'vue';
import { Colors } from '~/constants/colors.constants';
import type { ISharedGroupLinksProps } from '~/components/SharedGroupLinks/SharedGroupLinks.types';

const COLOR_DEFAULT = '--color';
const COLOR_ACTIVE = '--color-active';

const props = withDefaults(defineProps<ISharedGroupLinksProps>(), {
  colorActive: Colors.MAIN.GOLD,
  colorDefault: Colors.NEUTRAL.WHITE,
});

const { colorActive, colorDefault } = toRefs(props);

const styles = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};
  properties[COLOR_DEFAULT] = colorDefault.value;
  properties[COLOR_ACTIVE] = colorActive.value;
  return properties;
});
</script>

<style src="./SharedGroupLinks.scss" scoped lang="scss"></style>
