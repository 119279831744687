<template>
  <SharedContainerForm :logo="logo" :title="title" class="registration-form">
    <template #form>
      <div class="registration-form__inner">
        <div class="registration-form__line" :style="style"></div>
        <SharedDisplayData :keys-title="keysTitle" :info="info" />
        <SharedButton v-bind="buttonOptions" class="registration-form__buttons" @click="copy"
          >Скопировать данные
        </SharedButton>

        <div class="registration-form__save">
          <SharedText class="registration-form__save-info">Не забудьте сохранить ваш логин и пароль</SharedText>
          <SharedGroupLinks class="registration-form__save-link" :links="linksRegistrationForm" />
        </div>

        <SharedField v-model="email" :options-placeholder="defaultOptionsPlaceholder" placeholder="Введите email" />
        <SharedButton
          v-bind="buttonOptions"
          class="registration-form__buttons"
          :disabled="pending"
          @click="emit('on-send', email)"
          >Отправить данные
        </SharedButton>
      </div>
    </template>
  </SharedContainerForm>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed, ref } from 'vue';
import SharedContainerForm from '~/components/SharedContainerForm/SharedContainerForm.vue';
import SharedButton from '~/components/SharedButton/SharedButton.vue';
import SharedDisplayData from '~/components/SharedDisplayData/SharedDisplayData.vue';
import {
  keysTitle,
  linksRegistrationForm,
  defaultOptionsPlaceholder,
} from '~/features/authentication/components/AuthorizationForm/RegistrationInfoForm/RegistrationInfoForm.data';

import type {
  IRegistrationInfoFormEmit,
  IRegistrationInfoFormProps,
} from '~/features/authentication/components/AuthorizationForm/RegistrationInfoForm/RegistrationInfoForm.types';
import { Colors } from '~/constants/colors.constants';
import SharedGroupLinks from '~/components/SharedGroupLinks/SharedGroupLinks.vue';

const emit = defineEmits<IRegistrationInfoFormEmit>();
const props = withDefaults(defineProps<IRegistrationInfoFormProps>(), {
  colorLine: Colors.MAIN.PURPLE_200,
});

const email = ref<string>('');

const style = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};
  properties.backgroundColor = props.colorLine;
  return properties;
});

const transformDataToString = (): string => {
  let result = '';
  for (const key in props.info) {
    result += `${key}: ${props.info[key]} `;
  }

  return result.trim();
};

const copy = () => navigator.clipboard.writeText(transformDataToString());
</script>

<style src="./RegistrationInfoForm.scss" scoped lang="scss"></style>
