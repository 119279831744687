import type { TRecordComponentAuth } from '~/features/authentication/components/AuthorizationFormWidget/AuthorizationFormWidget.types';
import AuthorizationForm from '~/features/authentication/components/AuthorizationForm/AuthorizationForm.vue';
import LoginForm from '~/features/authentication/components/AuthorizationForm/LoginForm/LoginForm.vue';
import RegistrationInfoForm from '~/features/authentication/components/AuthorizationForm/RegistrationInfoForm/RegistrationInfoForm.vue';

export const titleComponent: TRecordComponentAuth<string> = {
  auth: 'Вход',
  login: 'Авторизация',
  registration: 'Благодарим вас за регистрацию!',
};

export const dynamicComponentProps: TRecordComponentAuth<object> = {
  auth: AuthorizationForm,
  login: LoginForm,
  registration: RegistrationInfoForm,
};
