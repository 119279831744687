<template>
  <div class="form" :style="styles">
    <div v-if="!noLogo">
      <SharedLogo v-if="logo" :name="logo"></SharedLogo>
    </div>
    <SharedText v-if="title" v-bind="bindTextProps" class="form__title">{{ title.text }}</SharedText>
    <div class="form__inner">
      <slot name="form" />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed, toRefs } from 'vue';
import type { IPropsSharedContainerForm } from '~/components/SharedContainerForm/SharedContainerForm.types';
import { Colors } from '~/constants/colors.constants';
import { defaultTextOptions } from '~/components/SharedContainerForm/SharedContainerForm.data';
import type { ITextProps } from '~/types/SharedFont.types';

const props = withDefaults(defineProps<IPropsSharedContainerForm>(), {
  backgroundColor: Colors.MAIN.PURPLE_500,
  logo: 'td',
});

const { backgroundColor } = toRefs(props);

const bindTextProps = computed<ITextProps>(() => {
  if (!props.title) return defaultTextOptions;
  return props.title.textProps ? props.title.textProps : defaultTextOptions;
});

const styles = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};
  if (backgroundColor.value) {
    const colorsStyles = GlobalUtils.CSS.parseColor(backgroundColor.value);
    if (colorsStyles.backgroundColor) properties.backgroundColor = colorsStyles.backgroundColor;
    properties.backgroundImage = colorsStyles.backgroundImage;
  }
  return properties;
});
</script>

<style src="./SharedContainerForm.scss" scoped lang="scss"></style>
