import type { ISharedIconSocial } from '~/components/SharedGroupIcons/SharedGroupIcons.types';

export const defaultIcons: ISharedIconSocial[] = [
  {
    componentName: 'SteamLargeIcon',
    eventName: 'steam',
    isFixed: true,
    isLong: true,
    size: 22,
  },
  {
    componentName: 'NewGoogleIcon',
    eventName: 'google',
    isFixed: true,
    size: 22,
  },
  {
    componentName: 'NewVkIcon',
    eventName: 'vk',
    isFixed: true,
    size: 22,
  },
];
