<template>
  <SharedContainerForm :logo="logo" :title="title" class="login-form">
    <template #form>
      <div class="login-form__inner">
        <SharedField
          v-model="formData.login"
          :options-placeholder="defaultOptionsPlaceholder"
          input-style="purple"
          placeholder="Введите логин"
        />
        <SharedField
          v-model="formData.password"
          :options-placeholder="defaultOptionsPlaceholder"
          input-style="purple"
          placeholder="Введите пароль"
        />

        <div v-if="error" class="login-form__errors">
          <SharedText as="span" :color="Colors.ADDITIONAL.RED">{{ error }}</SharedText>
        </div>
        <SharedButton
          v-bind="buttonOptions"
          class="login-form__button"
          :disabled="pending"
          @click="emit('on-auth', formData)"
        >
          Войти
        </SharedButton>

        <SharedGroupLinks :links="links" />
      </div>
    </template>
  </SharedContainerForm>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { Colors } from '~/constants/colors.constants';
import {
  defaultOptionsPlaceholder,
  linksLoginForm,
} from '~/features/authentication/components/AuthorizationForm/LoginForm/LoginForm.data';
import SharedContainerForm from '~/components/SharedContainerForm/SharedContainerForm.vue';
import SharedButton from '~/components/SharedButton/SharedButton.vue';
import type {
  ILoginFormEmit,
  ILoginFormProps,
} from '~/features/authentication/components/AuthorizationForm/LoginForm/LoginForm.types';
import SharedGroupLinks from '~/components/SharedGroupLinks/SharedGroupLinks.vue';

const formData = reactive({
  login: '',
  password: '',
});

const emit = defineEmits<ILoginFormEmit>();
defineProps<ILoginFormProps>();

const links = linksLoginForm(emit);
</script>

<style src="./LoginForm.scss" scoped lang="scss"></style>
