<template>
  <Transition mode="out-in" name="fade">
    <component
      :is="component"
      :pending="isPending"
      :error="error"
      :title="title"
      :info="registrationInfo"
      :button-options="{ size: 'large', width: '100%', textProps: { size: computedFontSize } }"
      @on-registration="handlerClickRegistration"
      @on-login="handlerClickLogin"
      @on-social="handlerClickIcon"
      @on-auth="handleClickAuth"
      @on-back="handlerBack"
      @on-send="handlerSend"
    />
  </Transition>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onUnmounted, ref } from 'vue';
import { dynamicComponentProps, titleComponent } from './AuthorizationFormWidget.data';
import { FontSizes } from '~/types/SharedFont.types';
import { useAuthStore } from '~/features/authentication/store/authorization';
import type {
  IAuthorizationFormWidgetProps,
  TKeyComponent,
} from '~/features/authentication/components/AuthorizationFormWidget/AuthorizationFormWidget.types';
import type { ITitle } from '~/components/SharedContainerForm/SharedContainerForm.types';
import type { Dto, Type } from '~/repository/modules/auth';
import { AuthEvents } from '~/repository/amplitude/events/auth';

const changeMD = GlobalUtils.Media.changeByMedia('md');
const computedFontSize = computed(() => changeMD(FontSizes.LARGE, FontSizes.MEDIUM));

const props = defineProps<IAuthorizationFormWidgetProps>();
const { buttonsPlace } = toRefs(props);

const authStore = useAuthStore();
onUnmounted(() => authStore.clearError());
// Refs
const nameShowComponent = ref<TKeyComponent>('auth');
const title = computed<ITitle>(() => {
  return {
    text: titleComponent[nameShowComponent.value],
  };
});
const component = computed<object>(() => {
  return dynamicComponentProps[nameShowComponent.value];
});
const { error, isPending, registrationInfo } = storeToRefs(authStore);

const changeComponent = (name: TKeyComponent): void => {
  nameShowComponent.value = name;
  authStore.clearError();
};

const handlerClickRegistration = async () => {
  const data = await authStore.quickRegistration();
  if (data) changeComponent('registration');
};
const handlerClickLogin = () => {
  changeComponent('login');
};
const handlerClickIcon = async (type: Type.TTypesOAuthKey) => {
  if (buttonsPlace.value) {
    AuthEvents.buttonClicked({
      SignUpType: type,
      Url: location.href,
    });
  }

  await authStore.oauth(type);
};
const handlerBack = () => {
  changeComponent('auth');
};

const handlerSend = () => {};

const handleClickAuth = async (formData: Dto.LoginRequestDto) => {
  await authStore.login(formData);
};
</script>
